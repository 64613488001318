(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/component-registry/assets/use-component-from-registry.js') >= 0) return;  svs.modules.push('/components/tipsen/component-registry/assets/use-component-from-registry.js');
"use strict";


const {
  getLogger
} = svs.core.log;
const {
  moduleBuilder
} = svs.components;
const logger = getLogger('tipsen:use-component-from-registry');
const nullFn = () => null;
const NullComponent = nullFn;
const getComponent = (componentKey, fallbackComponent) => {
  const Component = moduleBuilder.componentRegistry.getComponent(componentKey);
  if (!Component) {
    logger.info("Component with key '".concat(componentKey, "' could not be found in the registry"));
    return fallbackComponent || NullComponent;
  }
  return Component;
};
const useComponentFromRegistry = (componentKey, fallbackComponent) => getComponent(componentKey, fallbackComponent);
setGlobal('svs.components.tipsen.componentRegistry', {
  useComponentFromRegistry
});

 })(window);